import React from "react";
import { useNavigate } from "react-router-dom";
import robotIcon from "../assets/images/robot.svg";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";

import { createRobot } from "../services/robot";
import { useRobot } from "../contexts/RobotContext";

const GetStartedContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7fafc;
`;

const FormContainer = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Logo = styled.img`
  width: 4rem;
  height: 4rem;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 16rem;
  padding: 0.75rem;
  border: 1px solid #cbd5e0;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
`;

const ErrorMessageStyled = styled.div`
  color: #e53e3e;
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;


const SubmitButton = styled.button`
  width: 16rem;
  padding: 0.75rem;
  background-color: #4299e1;
  color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
`;

const validationSchema = Yup.object().shape({
  robotName: Yup.string().required("Robot Name is required"),
});

const GetStarted: React.FC = () => {
  const navigate = useNavigate();
  const { setRobot } = useRobot();

  const handleCreateRobot = async (values: { robotName: string }, { setSubmitting }: any) => {
    try {
      setSubmitting(true);
      const response = await createRobot({name: values.robotName});
      const { data } = await response?.data;
      const slug = data?.attributes?.slug;
      const name = data?.attributes?.name;

      setRobot(
        {
          id: data?.id,
          slug,
          name,
          data
        }
      );
      navigate(`/robot/${slug}`);
    } catch (error) {
      console.error("Error creating robot:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <GetStartedContainer>
      <Formik
        initialValues={{ robotName: "" }}
        validationSchema={validationSchema}
        onSubmit={handleCreateRobot}
      >
        {({ isValid, isSubmitting }: FormikProps<{ robotName: string }>) => (
          <FormContainer>
            <Logo src={robotIcon} alt="Robot Icon" />
            <Title>Comece criando seu Robô</Title>
            <Form>
              <FormWrapper>
                <Field
                  type="text"
                  name="robotName"
                  as={Input}
                  placeholder="dê um nome super legal"
                />
                <ErrorMessage name="robotName" component={ErrorMessageStyled} />
                <SubmitButton type="submit" disabled={!isValid || isSubmitting}>
                  {isSubmitting ? `...` : `Criar`}
                </SubmitButton>
              </FormWrapper>
            </Form>
          </FormContainer>
        )}
      </Formik>
    </GetStartedContainer>
  );
};

export default GetStarted;
