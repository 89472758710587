import axios from "axios";

const defaultHeaders = {
  'Access-Control-Allow-Origin': process.env.REACT_APP_URL,
  'Content-Type': 'application/json',
  'Access-Control-Allow-Credentials': false
};

const createRobot = async (body: object) => {
  return axios
    .create(
      {
        baseURL: process.env.REACT_APP_ROBOT_API_URL,
        headers: Object.assign(defaultHeaders),
        withCredentials: false,
      })
    .post("robots", body);
};

const getRobot = async (id: string) => {
  return axios
    .create({baseURL: process.env.REACT_APP_ROBOT_API_URL, headers: Object.assign(defaultHeaders)})
    .get(`robots/${id}`);
};

const sendRobotCommand = async (id: string, command: string) => {
  return axios
    .create({baseURL: process.env.REACT_APP_ROBOT_API_URL, headers: Object.assign(defaultHeaders)})
    .post(`/robots/${id}/commands/${command}`);
};

const placeRobotCall = async (robot_id: string | undefined, body: object) => {
  return axios
    .create({baseURL: process.env.REACT_APP_ROBOT_API_URL, headers: Object.assign(defaultHeaders)})
    .post(`robots/${robot_id}/commands/place`, body);
};

export { createRobot, placeRobotCall, getRobot, sendRobotCommand };