import React from "react";
import ReactDOM from "react-dom";
import './assets/css/app.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { RobotProvider } from "./contexts/RobotContext";
import Tabletop from "./pages/Tabletop";
import GetStarted from "./pages/Getstarted";

import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/",
    element: <GetStarted />,
    errorElement: <div>404: page not found</div>,
  },
  {
    path: "robot/:robotId",
    element: <Tabletop />,
  },
]);

ReactDOM.render(
  <RobotProvider>
    <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
  </RobotProvider>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
