import React, { createContext, useContext, useState } from "react";
import { getRobot } from "../services/robot";
import { AxiosResponse } from "axios";

interface Robot {
  id: string | null;
  name: string | null;
  slug: string | null;
  data: object | null;
}

interface RobotContextProps {
  robot: Robot | null;
  setRobot: (robot: Robot | null) => void;
  getRobotData: (id: string) => Promise<AxiosResponse<any, any> | undefined>;
}

const RobotContext = createContext<RobotContextProps | null>(null);

export const useRobot = () => {
  const context = useContext(RobotContext);
  if (!context) {
    throw new Error("useRobot must be used within a RobotProvider");
  }
  return context;
};

export const RobotProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [robot, setRobot] = useState<Robot | null>(null);

  const getRobotData = async (id: string): Promise<AxiosResponse<any, any> | undefined> => {
    try {
      const robotData = await getRobot(id);
      return robotData;
    } catch (error) {
      console.log('error', error)
    }
  };

  return (
    <RobotContext.Provider value={{ robot, setRobot, getRobotData }}>
      {children}
    </RobotContext.Provider>
  );
};
