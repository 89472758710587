import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  placeRobot: (x: number, y: number, direction: string) => void;
  moveRobot: () => void;
  rotateRobot: (direction: string) => void;
}

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputGroup = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.label`
  margin-right: 5px;
`;

const Input = styled.input`
  width: 50px;
  padding: 5px;
  border: 1px solid #cbd5e0;
  border-radius: 0.25rem;
`;

const Select = styled.select`
  padding: 5px;
  border: 1px solid #cbd5e0;
  border-radius: 0.25rem;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const Button = styled.button`
  padding: 10px 15px;
  margin: 5px;
  background-color: #4299e1;
  color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
`;

const Controls: React.FC<Props> = ({ placeRobot, moveRobot, rotateRobot }) => {
  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [direction, setDirection] = useState("NORTH");

  const handlePlace = () => {
    if (x !== "" && y !== "") {
      placeRobot(Number(x), Number(y), direction);
      setX("");
      setY("");
    }
  };

  const handleMove = () => {
    moveRobot();
  };

  const handleLeft = () => {
    rotateRobot("LEFT");
  };

  const handleRight = () => {
    rotateRobot("RIGHT");
  };

  return (
    <ControlsContainer>
      <InputGroup>
        <Label>X:</Label>
        <Input
          type="number"
          value={x}
          onChange={(e) => setX(e.target.value)}
          placeholder="0-4"
          min="0"
          max="4"
        />
      </InputGroup>
      <InputGroup>
        <Label>Y:</Label>
        <Input
          type="number"
          value={y}
          onChange={(e) => setY(e.target.value)}
          placeholder="0-4"
          min="0"
          max="4"
        />
      </InputGroup>
      <InputGroup>
        <Label>Direction:</Label>
        <Select value={direction} onChange={(e) => setDirection(e.target.value)}>
          <option value="NORTH">NORTH</option>
          <option value="SOUTH">SOUTH</option>
          <option value="EAST">EAST</option>
          <option value="WEST">WEST</option>
        </Select>
      </InputGroup>
      <Buttons>
        <Button onClick={handlePlace}>PLACE</Button>
        <Button onClick={handleMove}>MOVE</Button>
        <Button onClick={handleLeft}>LEFT</Button>
        <Button onClick={handleRight}>RIGHT</Button>
      </Buttons>
    </ControlsContainer>
  );
};

export default Controls;