import React from "react";
import styled from "styled-components";
import robotIcon from "../assets/images/robot.svg";

interface Props {
  robot: {
    x: number | null;
    y: number | null;
    direction: string | null;
    isPlaced: boolean;
  };
}

const BoardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 100px);
  grid-template-rows: repeat(5, 100px);
`;

const Cell = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  position: relative;
`;

const RobotIcon = styled.img`
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #2196f3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Board: React.FC<Props> = ({ robot }) => {
  return (
    <BoardContainer>
      {[...Array(5)].map((_, y) => (
        <div key={y} className="row">
          {[...Array(5)].map((_, x) => (
            <Cell
              key={x}
              className={robot.x === x && robot.y === y ? "robot " + robot.direction!.toLowerCase() : ""}
            >
              {robot && robot.x === x && robot.y === y && <RobotIcon src={robotIcon} />}
            </Cell>
          ))}
        </div>
      ))}
    </BoardContainer>
  );
};

export default Board;
