import React, { useState, useEffect } from "react";
import Board from "../components/Board";
import Controls from "../components/Controls";
import styled, { keyframes } from "styled-components";
import Pusher from "pusher-js";
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'
import { useRobot } from "../contexts/RobotContext";
import { placeRobotCall, sendRobotCommand } from "../services/robot";
import { useLocation } from "react-router-dom";
import { head, last } from "ramda";
import { JSX } from "react/jsx-runtime";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FadeIn = styled.div`
  animation: ${fadeIn} .5s ease-in-out;
`;

const AppContainer = styled.div`
  text-align: center;
`;

const GameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Robot {
  x: number | null;
  y: number | null;
  direction: string | null;
  isPlaced: boolean;
}

interface PusherData {
  last_position: [number, number];
  last_direction: string;
  placed: boolean;
  name: string;
  slug: string;
  id: string;
}

const Loader = (props: JSX.IntrinsicAttributes & IContentLoaderProps) => (
  <ContentLoader
    speed={2}
    width={837}
    height={500}
    viewBox="0 0 837 500"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="1" y="2" rx="2" ry="2" width="837" height="500" />
  </ContentLoader>
)

const Tabletop: React.FC = () => {
  const location = useLocation();
  const { getRobotData } = useRobot();
  const [loading, setLoading] = useState<boolean>(true)
  const [localRobot, setRobot] = useState<Robot>({
    x: null,
    y: null,
    direction: null,
    isPlaced: false,
  });

  const channelName = location.pathname.split('/')[2];

  useEffect(() => {
    const fetchData = async () => {
      const response = await getRobotData(channelName);
      const { data } = await response?.data;
      const { attributes } = data;

      setLoading(false);

      if (attributes && attributes.placed) {
        const p_x = Number(head(attributes.last_position));
        const p_y = Number(last(attributes.last_position));

        setRobot({
          x: p_y ?? null,
          y: p_x ?? null,
          direction: attributes.last_direction ?? null,
          isPlaced: true,
        });
      }
    }

    fetchData();

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY!, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
    });

    const channel = pusher.subscribe(channelName);
    channel.bind('changed', function(data: PusherData) {
      if (data) {
        const p_x = Number(head(data?.last_position));
        const p_y = Number(last(data?.last_position));
        setRobot({ x: p_x, y: p_y, direction: data.last_direction, isPlaced: data.placed });
      }
    });

    return () => {
      channel.unsubscribe();
    };
  }, [channelName, getRobotData, localRobot.isPlaced, location.pathname]);

  const placeRobot = async (x: number, y: number, direction: string) => {
    if (x >= 0 && x < 5 && y >= 0 && y < 5) {
      try {
        setLoading(true);

        await placeRobotCall(channelName, {
          coordinate_x: x,
          coordinate_y: y,
          facing: direction,
        }).finally(() => {
          setLoading(false);
        });

      } catch (error) {
        console.error("Error placing robot:", error);
      }
    }
  };

  const moveRobot = async () => {
    if (!localRobot.isPlaced) return;
    try {
      setLoading(true);
      await sendRobotCommand(channelName, "move").finally(() => {
        setLoading(false);
      });
    } catch (error) {
      console.error("Error moving robot:", error);
    }

  };

  const rotateRobot = async (direction: string) => {
    if (!localRobot.isPlaced) return;

    try {
      setLoading(true);
      await sendRobotCommand(channelName, direction.toLowerCase()).finally(() => {
        setLoading(false);
      });
    } catch (error) {
      console.error("Error rotating robot:", error);
    }
  };

  return (
    <AppContainer>
      <h1 className="text-3xl font-bold my-6">Toy Robot Game</h1>
      <GameContainer>
        {loading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <FadeIn>
              <Board robot={localRobot} />
            </FadeIn>
          <Controls placeRobot={placeRobot} moveRobot={moveRobot} rotateRobot={rotateRobot} />
          </>
        )}

      </GameContainer>
    </AppContainer>
  );
};

export default Tabletop;
